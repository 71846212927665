import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components//ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAppStore } from "@/stores";
import { useAuth } from "@saas-ui/auth";
import useSWR from "swr";
import { changeCompany, getCompanies } from "@/lib/supabase";
import { cn } from "@/lib/utils";
import { CheckIcon, CircleUser, Plus, User } from "lucide-react";
import {
  abbreviateLongString,
  getFullName,
  getUserInitials,
} from "@revelate/utils";
import { NewUserForm } from "./new-user-form";
import { useState } from "react";
import { Loader } from "@/components/Loader";
import { TODO } from "@revelate/types";

export function UserNav() {
  const { currentUser, reset: resetStore } = useAppStore((state) => state);
  const [open, setOpen] = useState(false);

  const { data: companies } = useSWR(currentUser, getCompanies, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const auth = useAuth();
  const logOut = () => {
    auth.logOut();
    resetStore();
  };

  if (!currentUser) return <Loader />;
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="secondary"
            size="icon"
            className="relative h-9 w-9 rounded-full"
          >
            {currentUser?.avatar_url ? (
              <Avatar className="h-9 w-9">
                <AvatarImage
                  src={currentUser?.avatar_url || ""}
                  alt={getFullName(currentUser)}
                />
                <AvatarFallback>{getUserInitials(currentUser)}</AvatarFallback>
              </Avatar>
            ) : (
              <CircleUser className="h-5 w-5" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {getFullName(currentUser)}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {currentUser?.email || "Anonymous"}
              </p>
            </div>
          </DropdownMenuLabel>

          {currentUser?.role === "super_admin" && (
            <>
              <DropdownMenuSeparator />
              {companies?.map((c: TODO) => (
                <DropdownMenuItem
                  className="cursor-pointer"
                  key={c.id}
                  onClick={async () => {
                    await changeCompany(currentUser, c.id);
                    window.location.reload();
                    // supabaseClient.auth
                    //   .getSession()
                    //   .then(({ data: { session } }) => {
                    //     fetch(session);
                    //   });
                  }}
                >
                  {abbreviateLongString(c.name, 20)}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      currentUser?.company_id === c.id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  <DropdownMenuShortcut>{c.id}</DropdownMenuShortcut>
                </DropdownMenuItem>
              ))}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                key="add-user"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new user
                <DropdownMenuShortcut>
                  <User className="mr-2 h-4 w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuGroup>
            {/* <DropdownMenuItem>
            Profile
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem> */}
            {/* <DropdownMenuItem>
            Billing
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            Settings
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>New Team</DropdownMenuItem> */}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="cursor-pointer" onClick={() => logOut()}>
            Log out
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {currentUser?.role === "super_admin" && (
        <NewUserForm companies={companies} open={open} setOpen={setOpen} />
      )}
    </>
  );
}
