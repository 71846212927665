import {
  RevelateDealType,
  Database,
  DateRange,
  Scope,
  NestedDeal,
  NestedCompany,
  NestedCommission,
  TokenProvider,
  NestedTeam,
  DealRole,
} from "@revelate/types";
import {
  addMonths,
  areIntervalsOverlapping,
  differenceInCalendarWeeks,
  differenceInMonths,
  endOfDay,
  isWithinInterval,
  startOfDay,
  subHours,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { getUserIdsForTeam } from "./teams";
import { getExchangeRateForDeal } from "./currencies";
import { isMultipleOf } from "@revelate/utils";

export type NestedDealStalled = NestedDeal & { comment?: string };

export const isInvoice = (deal: NestedDeal) => {
  return deal.provider === "fortnox";
};

export const sortDealsByAmount = (
  deals: Database["public"]["Tables"]["deals"]["Row"][]
) => {
  return deals.sort((a, b) => a.value - b.value);
};

export const getDealValue = (
  company: NestedCompany | null,
  deal: NestedDeal,
  useOnboardingValue: boolean | null = false,
  logs?: string[]
) => {
  return (
    (useOnboardingValue ? deal.onboarding_value || 0 : deal.value || 0) *
    getExchangeRateForDeal(company, deal, logs)
  );
};

export const getDealsValue = (
  company: NestedCompany | null,
  deals: NestedDeal[],
  useOnboardingValue: boolean | null = false,
  logs?: string[]
) => {
  return (
    deals?.reduce(
      (acc, deal) =>
        acc + getDealValue(company, deal, useOnboardingValue, logs),
      0
    ) || 0
  );
};

export const getAverageDealValue = (
  company: NestedCompany | null,
  deals: NestedDeal[]
) => {
  const totalValue = getDealsValue(company, deals);
  return totalValue / (deals.length || 1);
};

export const getDealsForScope = ({
  deals,
  scope,
  teams,
}: {
  deals: NestedDeal[];
  scope?: Scope | null;
  teams?: NestedTeam[];
}) => {
  // Get deals for scope
  if (!scope) return deals;
  const { type } = scope;
  if (type === "user") {
    return deals.filter((deal) => getDealOwner(deal)?.id === scope.value);
  }
  // TODO: Add team scope by filtering on users within a team
  if (teams && type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return deals.filter(
      (deal) =>
        getDealOwner(deal) && userIds.includes(getDealOwner(deal)?.id || "")
    );
  }
  return deals;
};

const filterDealByDateRange = ({
  deals,
  dateRange,
  isProjected = false,
}: {
  deals: NestedDeal[];
  dateRange: DateRange;
  isProjected?: boolean;
}) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return deals;
  const today = new Date();

  // Adjust range boundaries to handle end-of-month deals
  // Subtract 2 hours from the start of day to ensure deals after 22:00 UTC are counted in the next month
  const fromUTC = fromZonedTime(subHours(startOfDay(new Date(from)), 2), "UTC");
  const toUTC = fromZonedTime(subHours(endOfDay(new Date(to)), 2), "UTC");

  const filteredDeals = deals.filter((deal) => {
    // Convert deal closed_at to UTC and adjust time to match our boundary logic
    const dealClosedAtUTC = deal.closed_at
      ? fromZonedTime(new Date(deal.closed_at), "UTC")
      : null;

    // Projected deals are assigned an expected closing date by the CRM system that we can use here
    const isClosedWithinRange =
      dealClosedAtUTC &&
      isWithinInterval(dealClosedAtUTC, {
        start: fromUTC,
        end: toUTC,
      });

    const nextInvoiceDueWithinRange =
      dealClosedAtUTC &&
      deal.contract_length_months &&
      deal.customer_deal_count === 1 && // Only count the first invoice
      // Contract is still live - using UTC dates for comparison
      areIntervalsOverlapping(
        {
          start: fromZonedTime(subHours(startOfDay(dealClosedAtUTC), 2), "UTC"),
          end: fromZonedTime(
            subHours(
              endOfDay(addMonths(dealClosedAtUTC, deal.contract_length_months)),
              2
            ),
            "UTC"
          ),
        },
        {
          start: fromUTC,
          end: toUTC,
        }
      );

    const nextInvoiceDueThisMonth =
      dealClosedAtUTC &&
      deal.invoice_length_months &&
      differenceInMonths(today, dealClosedAtUTC) > 0 &&
      isMultipleOf(
        differenceInMonths(today, dealClosedAtUTC),
        deal.invoice_length_months
      );

    return isProjected && isInvoice(deal)
      ? nextInvoiceDueWithinRange && nextInvoiceDueThisMonth
      : isClosedWithinRange;
  });

  return filteredDeals;
};

export const filterByDealRole = (
  deals: NestedDeal[],
  dealRole: DealRole,
  userId: string
) => {
  // Filter by deal role
  return deals.filter((deal) => {
    // TODO: Filter by user id
    const dealUser = deal?.users?.find(
      (dealUser) => dealUser?.user_id === userId
    );
    if (dealRole === "management") return true; // All deals
    return dealUser && dealUser?.role === dealRole;
  });
};

export const getDealsForDateRange = ({
  deals: inputDeals,
  dateRange,
  scope,
  teams,
  dealTypes,
  providers,
  dealRole,
  userId,
  isProjected = false,
}: {
  deals: NestedDeal[];
  dateRange: DateRange;
  scope?: Scope | null;
  teams?: NestedTeam[];
  dealTypes?: RevelateDealType[];
  providers?: TokenProvider[];
  dealRole?: DealRole;
  userId?: string;
  isProjected?: boolean;
}) => {
  // Filter by scope
  let deals = getDealsForScope({ deals: inputDeals, scope, teams });
  if (!deals) return [];
  // Filter by date range
  deals = filterDealByDateRange({ deals, dateRange, isProjected });
  if (dealTypes) {
    // Filter by deal type
    deals = deals.filter((deal) =>
      dealTypes.includes(deal.deal_type as RevelateDealType)
    );
  }
  if (providers) {
    // Filter by provider
    deals = deals.filter((deal) => providers.includes(deal.provider));
  }

  if (dealRole && userId) {
    // Filter by deal role
    deals = filterByDealRole(deals, dealRole, userId);
  }

  if (isProjected) {
    // For invoices we include all invoices to be able to preserve the date range filter for next invoice
    deals = deals.filter((d) =>
      isInvoice(d) ? true : !d.is_won && !d.is_closed
    );
  } else {
    deals = deals.filter((d) => d.is_won && d.is_closed);
  }
  return deals?.sort((a, b) => b.value - a.value);
};

export const getDealValueFromCommissions = (
  commissions: NestedCommission[]
) => {
  if (!commissions || commissions.length === 0) return 0;
  return commissions?.reduce(
    (acc, commission) => commission.deal?.value ?? acc ?? 0, // don't accumulate, just take a found value
    0
  );
};

export const getStalledDeals = (
  projectedDeals: NestedDeal[],
  limit: number = 3
): NestedDealStalled[] => {
  return projectedDeals
    .map(mapStalledDealWithReason)
    .filter((d) => d !== null)
    .sort((a, b) => b.value - a.value)
    .slice(0, limit);
};

const mapStalledDealWithReason = (
  deal: NestedDeal
): NestedDealStalled | null => {
  const today = new Date();

  const sameStageDurationInWeeks = deal.next_step_updated_at
    ? differenceInCalendarWeeks(today, deal.next_step_updated_at)
    : undefined;

  const isNextStepAssigned = deal.next_step && deal.next_step.length > 0;

  const lastUpdatedAgoInWeeks = deal.last_activity_at
    ? differenceInCalendarWeeks(today, deal.last_activity_at)
    : undefined;

  if (sameStageDurationInWeeks && sameStageDurationInWeeks > 3) {
    return {
      ...deal,
      comment: `Same stage for ${sameStageDurationInWeeks} weeks`,
    };
  }

  if (!isNextStepAssigned) {
    return { ...deal, comment: "No next step assigned" };
  }

  if (lastUpdatedAgoInWeeks && lastUpdatedAgoInWeeks > 2) {
    return { ...deal, comment: `No updates in ${lastUpdatedAgoInWeeks} weeks` };
  }

  return null;
};

export const getDealOwner = (deal: NestedDeal) => {
  const owner = deal?.users?.find((user) => user.role === "owner");
  return owner?.data || null;
};
