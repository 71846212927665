import {
  Database,
  DateRange,
  NestedCommission,
  UserWithDeals,
} from "@revelate/types";
import { supabaseClient } from "./client";

export const upsertCommission = async (
  commission: Database["public"]["Tables"]["commissions"]["Insert"]
) => {
  const { data, error } = await supabaseClient
    .from("commissions")
    .upsert(
      {
        ...commission,
        updated_at: new Date().toISOString(),
      },
      {
        onConflict: "id",
        ignoreDuplicates: false,
      }
    )
    .select("*")
    .returns<Database["public"]["Tables"]["commissions"]["Row"][]>()
    .maybeSingle();
  if (error) {
    console.error("Error creating commission", error);
    return null;
  }
  return data;
};

export const deleteCommission = async (id: number) => {
  const { error } = await supabaseClient
    .from("commissions")
    .delete()
    .eq("id", id);
  if (error) {
    console.error("Error deleting commission", error);
    return null;
  }
  return id;
};

export const getCommissions = async (
  user_ids: string[],
  dateRange?: DateRange
) => {
  let query = supabaseClient
    .from("commissions")
    .select(
      "*, deal:deal_id(*), plan:plan_id(*), accelerator:accelerator_id(*), user:user_id(*)"
    )
    .in("user_id", user_ids);

  if (dateRange && dateRange.from && dateRange.to) {
    query = query
      .gte("month", dateRange.from.getMonth() + 1)
      .gte("year", dateRange.from.getFullYear())
      .lte("month", dateRange.to.getMonth() + 1)
      .lte("year", dateRange.to.getFullYear());
  }

  const { data, error } = await query
    .order("created_at", { ascending: false })
    .returns<NestedCommission[]>();
  if (error) {
    console.error("Error getting commissions", error);
    return [];
  }
  return data;
};

export const transferCommissions = async (
  fromUser: UserWithDeals,
  toUser: UserWithDeals
) => {
  const { id: fromUserId } = fromUser;
  const { id: toUserId } = toUser;

  const query = supabaseClient
    .from("commissions")
    .update({ user_id: toUserId })
    .eq("user_id", fromUserId);
  const { error } = await query;
  if (error) {
    throw error;
  }
};
