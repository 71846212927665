import { Database, NestedPlan, UserWithDeals } from "@revelate/types";
import { supabaseClient } from "./client";

export const getPlans = async (companyId: string): Promise<NestedPlan[]> => {
  const query = supabaseClient
    .from("plans")
    .select("*, users(*), accelerators(*, conditions(*)), teams(*)")
    .eq("company_id", Number(companyId));
  const { data, error } = await query.returns<NestedPlan[]>();
  if (error) {
    throw error;
  }
  return data;
};

export const getPlan = async (planId?: string) => {
  if (!planId) {
    return null;
  }
  const query = supabaseClient
    .from("plans")
    .select("*, users(*), accelerators(*, conditions(*)), teams(*)")
    .eq("id", Number(planId));

  const { data, error } = await query
    .returns<NestedPlan[]>()
    .limit(1)
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const upsertPlan = async (
  plan: Database["public"]["Tables"]["plans"]["Insert"]
): Promise<NestedPlan | null> => {
  const query = supabaseClient.from("plans").upsert({
    ...plan,
    updated_at: new Date(),
  });

  const { data, error } = await query
    .select("*, users(*), accelerators(*, conditions(*))")
    .returns<NestedPlan[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const deletePlan = async (planId: number) => {
  const query = supabaseClient.from("plans").delete().eq("id", planId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const addUserToPlan = async (planId: number, userId: string) => {
  const query = supabaseClient
    .from("user_plans")
    .insert({ user_id: userId, plan_id: planId });
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const deleteUserFromPlan = async (planId: number, userId: string) => {
  const query = supabaseClient
    .from("user_plans")
    .delete()
    .eq("user_id", userId)
    .eq("plan_id", planId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const addTeamToPlan = async (planId: number, teamId: string) => {
  const query = supabaseClient
    .from("team_plans")
    .insert({ team_id: teamId, plan_id: planId });
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const deleteTeamFromPlan = async (planId: number, teamId: string) => {
  const query = supabaseClient
    .from("team_plans")
    .delete()
    .eq("team_id", teamId)
    .eq("plan_id", planId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const transferPlans = async (
  fromUser: UserWithDeals,
  toUser: UserWithDeals
) => {
  const { id: fromUserId } = fromUser;
  const { id: toUserId } = toUser;

  const query = supabaseClient
    .from("user_plans")
    .update({ user_id: toUserId })
    .eq("user_id", fromUserId);
  const { error } = await query;
  if (error) {
    throw error;
  }
};
