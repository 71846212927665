import { Database, NestedGoal, UserWithDeals } from "@revelate/types";
import { supabaseClient } from "./client";

export const deleteGoal = async (goalId: number) => {
  const query = supabaseClient.from("goals").delete().eq("id", goalId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const upsertGoal = async (
  goal: Database["public"]["Tables"]["goals"]["Insert"]
): Promise<NestedGoal | null> => {
  const query = supabaseClient.from("goals").upsert({
    ...goal,
    updated_at: new Date(),
  });

  const { data, error } = await query
    .select("*")
    .returns<NestedGoal[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const addUserToGoal = async (goalId: number, userId: string) => {
  const query = supabaseClient
    .from("user_goals")
    .insert({ user_id: userId, goal_id: goalId });
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const deleteUserFromGoal = async (goalId: number, userId: string) => {
  const query = supabaseClient
    .from("user_goals")
    .delete()
    .eq("user_id", userId)
    .eq("goal_id", goalId);
  const { error } = await query;
  if (error) {
    throw error;
  }
};

export const transferGoals = async (
  fromUser: UserWithDeals,
  toUser: UserWithDeals
) => {
  const { id: fromUserId } = fromUser;
  const { id: toUserId } = toUser;

  const query = supabaseClient
    .from("user_goals")
    .update({ user_id: toUserId })
    .eq("user_id", fromUserId);
  const { error } = await query;
  if (error) {
    throw error;
  }
};
