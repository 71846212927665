import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { OverviewBarChart } from "@/dashboard/components/overview";
import { groupCommissionsByDealName } from "@/lib/app";
import { useAppStore } from "@/stores";
import { Route, Switch, useLocation } from "wouter";
import { Scope } from "./components/scope";
import { RecentDealsCard, RecentSalesCard } from "./cards";
import {
  getDealsForDateRange,
  getKeyDeals,
  getUsersForScope,
  getStalledDeals,
  getCommissionForDateRange,
} from "@revelate/calc";
import useSWR, { KeyedMutator } from "swr";
import { LineChart } from "lucide-react";
import { Button } from "@/components/ui/button";
import { getRoute } from "@/lib/routes";
import { TableType, get, getAll } from "@/lib/supabase";
import { LoadingError } from "@/components/LoadingError";
import { NestedUser, TODO } from "@revelate/types";
import { Loader } from "@/components/Loader";
import { CommissionKeyValues } from "./components/commission-key-values";
import { DealKeyValues } from "./components/deal-key-values";
import { KeyDealsMonth, KeyDealsNextQuarter } from "./components/key-deals";
import { StalledDeals } from "./components/stalled-deals";
import {
  getCurrentMonthDateRange,
  getNextQuarterDateRange,
} from "@revelate/utils";
import {
  ComparisonSpeedometer,
  TargetSpeedometer,
} from "./components/target-speedometer";
import { getAPI, getCalcUrl } from "@/lib/api";

export default function DashboardPage() {
  const { currentUser, scope, dateRange } = useAppStore((state) => state);
  const [, setLocation] = useLocation();

  const { data: company, isLoading: companyIsLoading } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  // -----------
  // Scoped data
  // -----------

  const {
    data: deals,
    error: dealsError,
    isLoading: dealsLoading,
  } = useSWR(
    [
      "deals" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const scopedDeals = getDealsForDateRange({
    deals: deals || [],
    dateRange,
    scope,
  });

  const scopedProjectedDeals = getDealsForDateRange({
    deals: deals || [],
    dateRange,
    scope,
    isProjected: true,
  });

  const stalledDeals = getStalledDeals(scopedProjectedDeals || []);

  const scopedUsers = company ? getUsersForScope(company, scope) : [];

  const {
    data: users,
    error: usersError,
    isLoading: usersLoading,
  }: {
    data: NestedUser[];
    error: TODO;
    mutate: KeyedMutator<NestedUser[] | null>;
    isLoading: boolean;
  } = useSWR(getCalcUrl(currentUser, dateRange), getAPI, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    data: projectedUsers,
    error: projectedUsersError,
    isLoading: projectedUsersLoading,
  }: {
    data: NestedUser[];
    error: TODO;
    mutate: KeyedMutator<NestedUser[] | null>;
    isLoading: boolean;
  } = useSWR(getCalcUrl(currentUser, dateRange, undefined, true), getAPI, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const commissions = users?.flatMap((user) => user.commissions);
  const scopedCommissions = commissions?.filter((commission) =>
    scopedUsers.map((u) => u.id).includes(commission.user_id)
  );

  const projectedCommissions = projectedUsers?.flatMap(
    (user) => user.commissions
  );
  const scopedProjectedCommissions = projectedCommissions?.filter(
    (commission) => scopedUsers.map((u) => u.id).includes(commission.user_id)
  );

  const scopedUserMonthlyCommissionTargets = scopedUsers
    ?.map((user) => user.commission_target)
    .filter((t) => t !== null);

  const projectedCommissionsThisMonth = getCommissionForDateRange(
    scopedProjectedCommissions,
    getCurrentMonthDateRange()
  );

  const projectedCommissionsNextQuarter = getCommissionForDateRange(
    scopedProjectedCommissions,
    getNextQuarterDateRange()
  );

  const keyDealsThisMonth = getKeyDeals(
    scopedUsers,
    groupCommissionsByDealName(projectedCommissionsThisMonth || [])
  );
  const keyDealsNextQuarter = getKeyDeals(
    scopedUsers,
    groupCommissionsByDealName(projectedCommissionsNextQuarter || [])
  );

  if (companyIsLoading || usersLoading || projectedUsersLoading) {
    return <Loader />;
  }
  if (usersError || projectedUsersError) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="flex-col md:flex">
        <div className="flex-1 space-y-4 py-0">
          <div className="flex items-center justify-between space-y-2">
            <Scope />
            <div className="flex items-center gap-4">
              {scope && (
                <Button
                  size="lg"
                  onClick={() =>
                    setLocation(getRoute("reports", scope?.type, scope?.value))
                  }
                >
                  <LineChart className="h-4 w-4 mr-2" />
                  View Report
                </Button>
              )}
            </div>
          </div>
          <Tabs defaultValue="commissions" className="space-y-4">
            <TabsList>
              <TabsTrigger value="commissions">Commissions</TabsTrigger>
              <TabsTrigger value="deals">Deals</TabsTrigger>
            </TabsList>
            <TabsContent value="commissions" className="space-y-4">
              <CommissionKeyValues
                commissions={scopedCommissions || []}
                projectedCommissions={scopedProjectedCommissions || []}
              />
            </TabsContent>
            <TabsContent value="deals" className="space-y-4">
              <DealKeyValues company={company} scopedDeals={scopedDeals} />
            </TabsContent>

            <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-6">
              <div className="col-span-3 flex flex-col gap-4">
                <TabsContent value="commissions">
                  <Card>
                    <CardHeader>
                      <CardTitle>Commission by month</CardTitle>
                      <CardDescription>
                        The commission earned per month
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="pl-2">
                      <OverviewBarChart
                        unapprovedCommissions={scopedCommissions || []}
                        projectedCommissions={scopedProjectedCommissions || []}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="deals">
                  <div className="flex flex-col gap-4">
                    <KeyDealsMonth keyDeals={keyDealsThisMonth} scope={scope} />
                    <KeyDealsNextQuarter
                      keyDeals={keyDealsNextQuarter}
                      scope={scope}
                    />
                    <StalledDeals deals={stalledDeals} scope={scope} />
                  </div>
                </TabsContent>
              </div>
              <div className="col-span-3 flex flex-col gap-4 mt-2">
                <Switch>
                  <Route path="/dashboard/user/:id">
                    <TabsContent value="commissions">
                      {company?.speedometer === "target_attainment" ? (
                        scopedUserMonthlyCommissionTargets.length > 0 && (
                          <div className="mt-[-8px]">
                            <TargetSpeedometer
                              userCommissions={scopedCommissions || []}
                              usersMonthlyTargets={
                                scopedUserMonthlyCommissionTargets
                              }
                              scope={scope}
                              dateRange={dateRange}
                            />
                          </div>
                        )
                      ) : (
                        <div className="mt-[-8px]">
                          <ComparisonSpeedometer
                            comparisonCommissions={commissions || []}
                            userCommissions={scopedCommissions || []}
                            scope={scope}
                          />
                        </div>
                      )}
                    </TabsContent>
                    <RecentDealsCard company={company} deals={deals || []} />
                  </Route>
                  <Route path="/dashboard/team/:id">
                    <TabsContent value="commissions">
                      <ComparisonSpeedometer
                        comparisonCommissions={commissions || []}
                        userCommissions={scopedCommissions || []}
                        scope={scope}
                      />
                    </TabsContent>
                    <RecentDealsCard company={company} deals={deals || []} />
                  </Route>
                  <Route>
                    <RecentSalesCard
                      scopedUsers={users?.filter((user) =>
                        scopedUsers.map((u) => u.id).includes(user.id)
                      )}
                      projectedUsers={projectedUsers}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
}
