import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { RecentDeals } from "./components/recent-deals";
import { RecentSales } from "./components/recent-sales";
import { getCurrencyFormatted } from "@/lib/app";
import { useAppStore } from "@/stores";
import {
  getCommissionsValue,
  getDealsForDateRange,
  getDealsValue,
} from "@revelate/calc";
import { NestedCompany, NestedDeal, NestedUser } from "@revelate/types";
import { getDateRangePreset } from "@revelate/utils";
import React from "react";

export const RecentDealsCard = ({
  company,
  deals,
}: {
  company: NestedCompany;
  deals: NestedDeal[];
}) => {
  const { scope, dateRange } = useAppStore((state) => state);

  const scopedDeals = getDealsForDateRange({
    deals: deals || [],
    dateRange,
    scope,
  });

  const scopedDealsWon = scopedDeals.filter((deal) => deal.is_won);

  return (
    <Card className="col-span-3">
      <CardHeader>
        <CardTitle>Based on {scopedDealsWon.length} deals</CardTitle>
        <CardDescription>
          {scope?.label.split(" ")[0]}'s won deal value this period is{" "}
          {getCurrencyFormatted(getDealsValue(company, scopedDealsWon))}.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RecentDeals deals={scopedDealsWon} />
      </CardContent>
    </Card>
  );
};

export const RecentSalesCard = ({
  scopedUsers,
  projectedUsers,
}: {
  scopedUsers: NestedUser[];
  projectedUsers: NestedUser[];
}) => {
  const dateRange = useAppStore((state) => state.dateRange);

  const getMatchingPresetValue = React.useCallback(() => {
    if (!dateRange?.from || !dateRange?.to) return "";
    // Find matching preset
    const matchingPreset = getDateRangePreset(dateRange);
    return matchingPreset
      ? `${matchingPreset.label.toLowerCase()}`
      : "this period";
  }, [dateRange]);
  // const scopedCommissions = useAppStore((state) => state.scopedCommissions);
  return (
    <Card className="col-span-3">
      <CardHeader>
        <CardTitle>Commission per employee</CardTitle>
        <CardDescription>
          The commission earned {getMatchingPresetValue().toLowerCase()} is{" "}
          {getCurrencyFormatted(
            getCommissionsValue(scopedUsers?.flatMap((u) => u.commissions))
          )}
          .
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RecentSales
          scopedUsers={scopedUsers}
          projectedUsers={projectedUsers}
        />
      </CardContent>
    </Card>
  );
};
