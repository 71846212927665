import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  addMonths,
  subMonths,
  addQuarters,
  subQuarters,
  addYears,
  subYears,
} from "date-fns";

// Define preset ranges
export const dateRangePresets = [
  {
    label: "Next month",
    range: {
      from: startOfMonth(addMonths(new Date(), 1)),
      to: endOfMonth(addMonths(new Date(), 1)),
    },
  },
  {
    label: "This month",
    range: {
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date()),
    },
  },
  {
    label: "Last month",
    range: {
      from: startOfMonth(subMonths(new Date(), 1)),
      to: endOfMonth(subMonths(new Date(), 1)),
    },
  },
  {
    label: "Next quarter",
    range: {
      from: startOfQuarter(addQuarters(new Date(), 1)),
      to: endOfQuarter(addQuarters(new Date(), 1)),
    },
  },
  {
    label: "This quarter",
    range: {
      from: startOfQuarter(new Date()),
      to: endOfQuarter(new Date()),
    },
  },
  {
    label: "Last quarter",
    range: {
      from: startOfQuarter(subQuarters(new Date(), 1)),
      to: endOfQuarter(subQuarters(new Date(), 1)),
    },
  },
  {
    label: "Next year",
    range: {
      from: startOfYear(addYears(new Date(), 1)),
      to: endOfYear(addYears(new Date(), 1)),
    },
  },
  {
    label: "This year",
    range: {
      from: startOfYear(new Date()),
      to: endOfYear(new Date()),
    },
  },
  {
    label: "Last year",
    range: {
      from: startOfYear(subYears(new Date(), 1)),
      to: endOfYear(subYears(new Date(), 1)),
    },
  },
];
