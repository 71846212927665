import * as React from "react";
import { CheckIcon, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getFullName, sortByStringKey } from "@revelate/utils";
import { NestedPlan } from "@revelate/types";
import { useAppStore } from "@/stores";
import type { KeyedMutator } from "swr";
import {
  TableType,
  addTeamToPlan,
  addUserToPlan,
  getAll,
} from "@/lib/supabase";
import { cn } from "@/lib/utils";
import useSWR from "swr";
import { Loader } from "@/components/Loader";

export function TeamsCombobox({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) {
  const [open, setOpen] = React.useState(false);
  const currentUser = useAppStore((state) => state.currentUser);

  const {
    data: teams,
    error,
    isLoading,
  } = useSWR(
    [
      "teams" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleSelect = async (value: string) => {
    setOpen(false);
    const team = teams?.find((e) => e.name.trim() === value.trim());
    if (!team) return;
    await addTeamToPlan(plan.id, team.id);
    const updatedTeams = [...(plan.teams || []), team];
    mutate({
      ...plan,
      teams: updatedTeams,
    } as NestedPlan);
  };

  if (isLoading) return <Loader />;
  if (error) return <div>Error loading teams</div>;

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button className="bg-[#6B6397]">
            <Plus className="mr-2 h-4 w-4" /> Add new team
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search team..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {teams
                  ?.filter((e) => !plan?.teams?.map((u) => u.id).includes(e.id))
                  ?.sort(sortByStringKey("first_name"))
                  .map((team) => (
                    <CommandItem
                      key={team.id}
                      value={team.name}
                      onSelect={(id) => handleSelect(id)}
                    >
                      <span>{team.name}</span>
                      <CheckIcon
                        className={cn("ml-auto h-4 w-4", "opacity-0")}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
