import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { NestedDeal, Scope } from "@revelate/types";
import { NestedDealsMiniDataTable, stalledDealsColumns } from "./data-table";
import { NestedDealStalled } from "@revelate/calc";
import { useAppStore } from "@/stores";
import React from "react";
import { getDateRangePreset } from "@revelate/utils";

interface StalledDealsProps {
  deals: NestedDealStalled[];
  scope: Scope | null;
}

export function StalledDeals({ deals, scope }: StalledDealsProps) {
  const dateRange = useAppStore((state) => state.dateRange);

  const getMatchingPresetValue = React.useCallback(() => {
    if (!dateRange?.from || !dateRange?.to) return "";
    // Find matching preset
    const matchingPreset = getDateRangePreset(dateRange);
    return matchingPreset
      ? `${matchingPreset.label.toLowerCase()}`
      : "this period";
  }, [dateRange]);

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Red flagged deals {getMatchingPresetValue()}</CardTitle>
          <CardDescription>
            {scope?.label.split(" ")[0]}'s deals which are not moving forward
          </CardDescription>
        </CardHeader>
        <CardContent>
          {deals.length > 0 ? (
            <NestedDealsMiniDataTable
              deals={deals}
              columns={stalledDealsColumns}
              scope={scope}
            />
          ) : (
            <CardDescription className="italic">
              No red-flagged deals found
            </CardDescription>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
