import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { getCurrencyFormatted, getUsersList } from "@/lib/app";
import { getRoute } from "@/lib/routes";
import { NestedUser } from "@revelate/types";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useLocation } from "wouter";

export function RecentSales({
  scopedUsers,
  projectedUsers,
}: {
  scopedUsers: NestedUser[];
  projectedUsers: NestedUser[];
}) {
  const [, setLocation] = useLocation();

  return (
    <div className="space-y-8">
      {getUsersList(scopedUsers, projectedUsers)?.map((user, index) => (
        <div className="flex items-center" key={index}>
          <Avatar className="h-9 w-9">
            <AvatarImage src={user.avatar} alt="Avatar" />
            <AvatarFallback>{user.avatar_fallback}</AvatarFallback>
          </Avatar>
          <div className="ml-4 space-y-1">
            <p className="text-sm font-medium leading-none">{user.name}</p>
            <p className="text-sm text-muted-foreground">
              <span className="text-foreground">
                {getCurrencyFormatted(user.valueProjected)}
              </span>{" "}
              projected in future
            </p>
          </div>
          <div className="ml-auto text-right font-medium">
            {getCurrencyFormatted(user.value)}
          </div>
          <div className="ml-3 font-medium">
            <Button
              onClick={() =>
                setLocation(getRoute("dashboard", "user", user.id))
              }
              variant="outline"
              size="icon"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
