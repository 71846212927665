import { Separator } from "@/components/ui/separator";
import { SidebarNav } from "./sidebar-nav";
import { Route, Switch, useParams } from "wouter";
import { SettingsPage } from "./settings/page";
import EmployeesPage from "./employees/page";
import useSWR from "swr";
import { Loader } from "lucide-react";
import { LoadingError } from "@/components/LoadingError";
import { TableType, get } from "@/lib/supabase";

export const EditTeamPage = () => {
  const { id } = useParams();

  const {
    data: team,
    error,
    isLoading,
    mutate,
  } = useSWR(["teams" as TableType, id], ([table, id]) => get(table, id), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const sidebarNavItems = [
    {
      title: "General",
      href: `/teams/team/${id || "new"}`,
    },
    {
      title: "Employees",
      href: `${id ? `/teams/team/${id}/employees` : "#"}`,
    },
  ];
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">
            {team?.name || "New team"}
          </h2>
          <p className="text-muted-foreground">
            {team
              ? "Manage your team's name and members."
              : "Create a new team to start paying commissions for employees."}
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-4xl">
            {
              <Switch>
                <Route path="/teams/team/new">
                  <SettingsPage mutate={mutate} />
                </Route>
                <Route path="/teams/team/:id">
                  {team && <SettingsPage team={team} mutate={mutate} />}
                </Route>
                <Route path={`/teams/team/:id/employees`}>
                  {team && <EmployeesPage team={team} mutate={mutate} />}
                </Route>
              </Switch>
            }
          </div>
        </div>
      </div>
    </>
  );
};
