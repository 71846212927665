import {
  NestedUser,
  NestedPlan,
  DateRange,
  NestedDeal,
  GroupedCommissions,
  KeyDeal,
  NestedTeam,
  CommissionBasedOn,
  TimePeriod,
} from "@revelate/types";
import { criteriaMet } from "./accelerators";
import {
  CommissionStatus,
  CommissionUser,
  NestedCommission,
  NestedCompany,
  Scope,
  TODO,
} from "@revelate/types";
import {
  format,
  parse,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { getUserIdsForTeam } from "./teams";
import {
  filterByDealRole,
  getDealsForDateRange,
  getDealsValue,
  getDealValueFromCommissions,
} from "./deals";
import {
  getStartEndDates,
  getStartOfYearToEndOfPreviousTimePeriod,
  shouldCalculate,
} from "@revelate/utils";
import {
  getExchangeRateForCurrencyId,
  getExchangeRateForDeal,
} from "./currencies";

const getCommissionBasedOn = (
  deal: NestedDeal,
  commission_based_on: string,
  isProjected: boolean = false
) => {
  let value = 0;

  switch (commission_based_on) {
    case "deal_value":
      value = deal.value || 0;
      break;
    case "onboarding_value":
      value = deal.onboarding_value ? deal.onboarding_value : 0;
      break;
    case "deal_value_arr":
      value = deal.value * 12 || 0;
      break;
    case "deal_value_above_target":
      console.log(
        "ERROR: Connect this accelerator to a quota to use deal_value_above_target"
      );
      return 0;
    case "deal_value_below_target":
      console.log(
        "ERROR: Connect this accelerator to a quota to use deal_value_below_target"
      );
      return 0;
    default:
      value = deal.value || 0;
  }

  const projectionMultiplier = isProjected ? deal.likelihood_to_close || 1 : 1;
  return value * projectionMultiplier;
};

const getCommissionBasis = (
  company: NestedCompany,
  deal: NestedDeal,
  commission_based_on?: string,
  isProjected: boolean = false
) => {
  const value = getCommissionBasedOn(
    deal,
    commission_based_on || "",
    isProjected
  );
  return value * getExchangeRateForDeal(company, deal);
};

const getAboveBelowCommissionBasis = (
  commission_based_on: CommissionBasedOn,
  targetValue: number,
  totalDealValue: number,
  previousTotalDealValue: number,
  isYTD: boolean
) => {
  if (commission_based_on === "deal_value_above_target") {
    const aboveTargetDealValue = isYTD
      ? totalDealValue + previousTotalDealValue
      : totalDealValue;
    const remainingDealValue = isYTD
      ? totalDealValue -
        (targetValue > previousTotalDealValue
          ? targetValue - previousTotalDealValue
          : 0)
      : totalDealValue - targetValue;
    return aboveTargetDealValue > targetValue ? remainingDealValue : 0;
  }
  if (commission_based_on === "deal_value_below_target") {
    const belowTargetValueRemaining = isYTD
      ? targetValue - previousTotalDealValue
      : targetValue;
    if (belowTargetValueRemaining <= 0) return 0;
    return totalDealValue > belowTargetValueRemaining
      ? belowTargetValueRemaining
      : totalDealValue;
  }
  return 0;
};

export const calculateCommissionsForPlan = ({
  calculationDate,
  dateRange,
  company,
  user,
  plan,
  isProjected = false,
  logs,
}: {
  calculationDate: Date;
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  plan: NestedPlan;
  isProjected: boolean;
  logs: string[];
}) => {
  if (!user || !plan || !dateRange) return null;

  const { time_period, deal_types, providers, accelerators, deal_role } =
    plan || {};

  // Check if we should use this plan for the given date
  if (!shouldCalculate(calculationDate, time_period) || !time_period)
    return null;

  const deals = filterByDealRole(company.deals || [], deal_role, user.id);

  // Filter the deals for the given time period in case we're looping through multiple
  const dealsForDateRange: NestedDeal[] = getDealsForDateRange({
    deals,
    dateRange: getStartEndDates(dateRange, calculationDate, time_period),
    dealTypes: deal_types,
    providers: providers || undefined,
    dealRole: deal_role,
    userId: user.id,
    isProjected,
  });

  const commissions: NestedCommission[] = [];
  const dealsPassed: NestedDeal[] = [];

  // User can now have multiple accelerators per plan that kick in
  // So we need to loop through all of them and check the conditions for each one
  if (!accelerators || accelerators.length === 0) return null;

  // Order accelerators by highest rate
  const sortedAccelerators = accelerators.sort((a, b) => b.rate - a.rate);
  for (const accelerator of sortedAccelerators) {
    const {
      commission_based_on,
      fixed_value_amount,
      fixed_value_currency_id,
      rate,
    } = accelerator;

    // Check if we meet the criteria for the accelerator, and filter the deals
    const {
      success,
      successQuotaTargetValue,
      successQuotaIsYTD,
      filteredDeals,
    } = criteriaMet({
      calculationDate,
      dateRange,
      company,
      user,
      deals,
      plan,
      accelerator,
      dealsForDateRange,
      logs,
    });
    if (!success) {
      continue;
    }
    const baseCommission: NestedCommission = {
      company_id: company.id,
      month: Number(format(calculationDate, "MM")),
      year: Number(format(calculationDate, "yyyy")),
      user_id: user.id,
      plan_id: plan.id,
      accelerator: accelerator,
      accelerator_id: accelerator.id,
      rate,
      plan,
      created_at: new Date() as TODO,
      updated_at: new Date() as TODO,
      status: isProjected ? "projected" : "unapproved",
      deal_id: null,
      deal: undefined,
      commission_basis: 0,
      amount: 0,
    };

    // Get deal value
    const totalDealValue = getDealsValue(company, filteredDeals);

    if (commission_based_on === "fixed_value" && fixed_value_amount) {
      // const deal = filteredDeals && filteredDeals.length === 1 ? filteredDeals[0] : null;
      const commission_basis = fixed_value_currency_id
        ? getExchangeRateForCurrencyId(company, fixed_value_currency_id) *
          fixed_value_amount
        : fixed_value_amount;
      const amount = commission_basis * rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        deals_count: filteredDeals?.length || 0,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };
      if (amount > 0) {
        commissions.push(commission);
      }
    } else if (
      (commission_based_on === "deal_value_above_target" ||
        commission_based_on === "deal_value_below_target") &&
      successQuotaTargetValue
    ) {
      const dealsForPreviousDateRange: NestedDeal[] = getDealsForDateRange({
        deals,
        dateRange: getStartOfYearToEndOfPreviousTimePeriod(
          calculationDate,
          time_period
        ),
        dealTypes: deal_types,
        providers: providers || undefined,
        dealRole: deal_role,
        userId: user.id,
        isProjected,
      });

      const previousTotalDealValue = dealsForPreviousDateRange
        ?.map((deal) => getExchangeRateForDeal(company, deal) * deal.value)
        .reduce((acc, value) => acc + value, 0);

      const targetValue = successQuotaTargetValue;

      const commission_basis = getAboveBelowCommissionBasis(
        commission_based_on,
        targetValue,
        totalDealValue,
        previousTotalDealValue,
        !!successQuotaIsYTD
      );

      const amount = commission_basis * rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        deals_count: filteredDeals?.length || 0,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };

      if (amount > 0) {
        commissions.push(commission);
        logs.push(
          `Accelerator matched: ${accelerator.name} (Accelerator ID: ${accelerator.id})`,
          `Commission based on: ${commission_based_on}`,
          `Deals passed: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getExchangeRateForDeal(company, deal) * deal.value}`).join(", ")})`,
          `YTD: ${successQuotaIsYTD}`,
          `Actual value previous period: ${previousTotalDealValue}`,
          `Actual value this period: ${totalDealValue}`,
          `Target value: ${successQuotaTargetValue}`,
          `Commission basis: ${commission_basis}`,
          `Rate: ${accelerator.rate}`,
          `Amount to be paid: ${amount}`,
          "\n"
        );
      }
    } else {
      for (const deal of filteredDeals) {
        const commission_basis = getCommissionBasis(
          company,
          deal,
          commission_based_on,
          isProjected
        );
        const amount = commission_basis * rate;
        const commission: NestedCommission = {
          ...baseCommission,
          deal_id: deal.id,
          deal: deal,
          commission_basis,
          amount,
        };
        dealsPassed.push(deal);
        commissions.push(commission);
      }
      logs.push(
        `Accelerator matched: ${accelerator.name} (Accelerator ID: ${accelerator.id})`,
        `Commission based on: ${commission_based_on} ${
          successQuotaTargetValue
            ? `(Actual value this period: ${totalDealValue}, Target value: ${successQuotaTargetValue})`
            : ""
        }`,
        `Deals passed: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getExchangeRateForDeal(company, deal) * deal.value}`).join(", ")})`,
        `Commission basis: ${commissions
          .reduce((acc, commission) => acc + commission.commission_basis, 0)
          .toString()}`,
        `Rate: ${accelerator.rate}`,
        `Amount to be paid: ${commissions.reduce((acc, commission) => acc + commission.amount, 0)}`,
        "\n"
      );
    }

    if (
      !accelerator?.conditions?.some(
        (condition) =>
          condition?.key?.includes("deals") &&
          !condition?.key?.includes("deals_customer_deal_count")
      )
    ) {
      break;
    }
  }

  return commissions;
};

export const calculateCommissionForUser = ({
  dateRange,
  company,
  user,
  isProjected,
  logs,
}: {
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  isProjected: boolean;
  logs: string[];
}) => {
  const allCommissions: NestedCommission[] = [];
  const { plans } = user;
  if (!plans || (plans && plans.length === 0)) {
    return allCommissions;
  }

  const { from, to } = dateRange || {};
  if (!from || !to) return allCommissions;

  const calculationDate = new Date(from);
  while (calculationDate <= new Date(to)) {
    logs.push(
      `-- ${Number(format(calculationDate, "yyyy"))}-${Number(
        format(calculationDate, "MM")
      )}: ${user.email} --\n`
    );

    for (const plan of plans) {
      const commissions = calculateCommissionsForPlan({
        calculationDate,
        dateRange,
        company,
        user,
        plan,
        isProjected,
        logs,
      });
      if (commissions && commissions.length > 0)
        allCommissions.push(...commissions);
    }
    calculationDate.setMonth(calculationDate.getMonth() + 1);
  }

  if (logs)
    logs.push(
      `Generated ${allCommissions.length} commissions for user ${user.email} between ${format(
        from,
        "yyyy-MM-dd"
      )} and ${format(to, "yyyy-MM-dd")}.`
    );
  return allCommissions;
};

export const getCommissionsValue = (commissions: NestedCommission[]) => {
  if (!commissions || commissions?.length === 0) return 0;
  return commissions?.reduce((acc, commission) => acc + commission?.amount, 0);
};

export const getCommissionForDateRange = (
  commissions: NestedCommission[],
  dateRange?: DateRange,
  filterOnStatuses?: CommissionStatus[]
) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return [];
  const scopedCommissions = commissions?.filter((commission) => {
    const commissionDate = parse(
      `${commission.year}-${commission.month}`,
      "yyyy-MM",
      new Date()
    );
    if (
      filterOnStatuses &&
      commission.status &&
      !filterOnStatuses.includes(commission.status)
    )
      return false;
    return (
      from &&
      to &&
      isWithinInterval(commissionDate, {
        start: startOfDay(from),
        end: endOfDay(to),
      })
    );
  });
  return scopedCommissions;
};

export const getTotalCommissionOfUsers = (users: CommissionUser[]) => {
  return users.reduce((acc, user) => acc + user.value, 0);
};

export const getMonthName = (commission: NestedCommission) => {
  const { year, month } = commission;
  // Get name of month from number
  const date = new Date(year, month - 1);
  return format(date, "MMMM", { locale: enUS });
};

export const getUsersForScope = (
  company: NestedCompany,
  scope?: Scope | null
) => {
  const { users, teams } = company || {};
  // Get users for scope
  if (!scope) return users;
  if (scope.type === "user") {
    return users.filter(
      (user) => user.id === scope.value && user?.plans?.length > 0
    );
  }
  // TODO: Add team scope by filtering on users within a team
  if (scope.type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return users.filter((user) => userIds.includes(user.id));
  }
  return users;
};

export const getPaidCommissions = (commissions: NestedCommission[]) => {
  return commissions.filter((commission) => commission.status === "paid");
};

export const getKeyDeals = (
  scopedUsers: NestedUser[],
  groupedCommissions: GroupedCommissions,
  limit: number = 3
): KeyDeal[] => {
  const keyDeals = Object.entries(groupedCommissions)
    .map(([dealName, commissions]) => {
      const isDealWon = commissions?.[0]?.deal?.is_won;
      const isDealLost = commissions?.[0]?.deal?.is_lost;
      if (isDealWon || isDealLost) return null;

      const dealValue = getDealValueFromCommissions(commissions);
      const commissionValue = getCommissionsValue(commissions);
      const owner = scopedUsers?.find(
        (user) => user.id === commissions?.[0]?.user_id
      );
      if (!owner) return null;
      return {
        dealName,
        dealValue,
        owner,
        commissionValue,
      } satisfies KeyDeal;
    })
    .filter((kd) => kd !== null)
    .toSorted((a, b) => b.dealValue - a.dealValue)
    .slice(0, limit);

  return keyDeals;
};

export const getCommissionsByQuotaTargetKeyDeals = (
  commissions: NestedCommission[],
  limit: number = 3
): NestedCommission[] => {
  return commissions
    .filter((c) => !c.deal?.is_won)
    .toSorted((a, b) => b.amount - a.amount)
    .slice(0, limit);
};

export const consolidateCommissions = (
  scopedCommissions: NestedCommission[],
  persistedCommissions?: NestedCommission[]
) => {
  // if a persisted commission is found for the given year, month, deal_id, and accelerator_id use it
  if (persistedCommissions) {
    scopedCommissions = scopedCommissions?.map((commission) => {
      const persistedCommission = persistedCommissions?.find(
        (c) =>
          c.year === commission.year &&
          c.month === commission.month &&
          c.deal_id === commission.deal_id &&
          c.accelerator_id === commission.accelerator_id &&
          c.user_id === commission.user_id
      );
      return persistedCommission || commission;
    });
  }
  return scopedCommissions;
};
