"use client";

import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "@/components/ui/checkbox";

// import { Task } from "../schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { dealTypesRadioButtons, getNumberFormatted } from "@/lib/app";
import { capitalizeFirstLetter } from "@revelate/utils";
import { Button } from "@/components/ui/button";
import { Lock, RefreshCcw } from "lucide-react";
import { format } from "date-fns";
import { NestedDeal, NestedToken, UserWithDeals } from "@revelate/types";
import { KeyedMutator } from "swr";
import { Icons } from "@/components/icons";
// import { DataTableRowActions } from "./data-table-row-actions";
import { ProviderDataViewer } from "./provider-data-viewer";
import { OwnerSelect } from "./owner-select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { updateDeal } from "@/lib/supabase";
import { getDealOwner } from "@revelate/calc";

type ColumnParams = {
  syncDealsByProvider: (
    provider: string,
    providerId: string
  ) => Promise<Response>;
  isAPILoading: boolean;
  clickedRow: string | null;
  setClickedRow: (value: string | null) => void;
  mutate: KeyedMutator<NestedDeal[]>;
  users?: UserWithDeals[];
  tokens?: NestedToken[];
};

const getStatus = (
  is_closed: boolean,
  is_won: boolean,
  is_invoiced: boolean,
  is_paid: boolean,
  is_read_only: boolean
) => {
  const statuses: string[] = [];
  if (is_closed) statuses.push("Closed");
  if (is_won) statuses.push("Won");
  if (is_invoiced) statuses.push("Invoiced");
  if (is_paid) statuses.push("Paid");
  if (is_read_only) statuses.push("Read Only");

  if (statuses.length === 0) {
    return "Pending";
  }

  return statuses.join(" » ");
};

const getStatusStep = (
  is_closed: boolean,
  is_won: boolean,
  is_invoiced: boolean,
  is_paid: boolean,
  is_read_only: boolean
) => {
  if (is_read_only) return 5;
  if (is_paid) return 4;
  if (is_invoiced) return 3;
  if (is_won) return 2;
  if (is_closed) return 1;
  return 0;
};

// TODO: Move this into a React Hook
export const getColumns = ({
  syncDealsByProvider,
  isAPILoading,
  clickedRow,
  setClickedRow,
  mutate,
  users,
  tokens,
}: ColumnParams): ColumnDef<NestedDeal>[] => {
  return [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Deal" />
      ),
      cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        // const label = labels.find((label) => label.value === row.original.label);
        const name = row.getValue("name")?.toString() || "";
        return (
          <div className="flex space-x-2">
            {/* {label && <Badge variant="outline">{label.label}</Badge>} */}
            <span className="max-w-[500px] truncate font-medium">
              {name.substring(0, 20) + (name.length > 20 ? "..." : "")}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "owner",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Owner" />
      ),
      cell: ({ row }) => {
        // const label = labels.find((label) => label.value === row.original.label);

        return (
          <div className="flex space-x-2">
            {/* {label && <Badge variant="outline">{label.label}</Badge>} */}
            <span className="max-w-[500px] truncate font-medium">
              <OwnerSelect
                row={row}
                users={users || []}
                mutate={mutate}
                tokens={tokens}
              />
              {/* {row.original.owner ? getFullName(row.original.owner) : "N/A"} */}
            </span>
          </div>
        );
      },
      filterFn: (row, _id, value) => {
        return getDealOwner(row.original)
          ? value.includes(getDealOwner(row.original)?.id)
          : false;
      },
    },
    {
      accessorKey: "deal_type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Deal type" />
      ),
      cell: ({ row }) => {
        // const status = statuses.find(
        //   (status) => status.value === row.getValue("status")
        // );
        const dealType = dealTypesRadioButtons.find(
          (d) => d.value === row.getValue("deal_type")
        );

        if (!dealType) {
          return null;
        }

        return (
          <div className="flex items-center">
            {/* {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{status.label}</span> */}
            <span>{dealType.label} business</span>
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "value",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Value" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {getNumberFormatted(row.getValue("value"))}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "currency_code",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Currency" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("currency_code")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "provider",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Provider" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {capitalizeFirstLetter(row.getValue("provider"))}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "provider_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Provider ID" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("provider_id")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      sortingFn: (rowA, rowB) => {
        const statusStepA = getStatusStep(
          rowA.getValue("is_closed"),
          rowA.getValue("is_won"),
          rowA.getValue("is_invoiced"),
          rowA.getValue("is_paid"),
          rowA.getValue("is_read_only")
        );
        const statusStepB = getStatusStep(
          rowB.getValue("is_closed"),
          rowB.getValue("is_won"),
          rowB.getValue("is_invoiced"),
          rowB.getValue("is_paid"),
          rowB.getValue("is_read_only")
        );
        return Math.sign(statusStepA - statusStepB);
      },
      filterFn: (row, _, filterValues) => {
        let match = false;

        if (filterValues.includes("Pending")) {
          match = row.original.is_closed === false || match;
        }

        if (filterValues.includes("Closed")) {
          match = row.original.is_closed === true || match;
        }

        if (filterValues.includes("Won")) {
          match = row.original.is_won === true || match;
        }

        if (filterValues.includes("Invoiced")) {
          match = row.original.is_invoiced === true || match;
        }

        if (filterValues.includes("Paid")) {
          match = row.original.is_paid === true || match;
        }

        if (filterValues.includes("Read Only")) {
          match = row.original.is_read_only === true || match;
        }

        return match;
      },
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {getStatus(
              row.getValue("is_closed"),
              row.getValue("is_won"),
              row.getValue("is_invoiced"),
              row.getValue("is_paid"),
              row.getValue("is_read_only")
            )}
          </div>
        );
      },
      // filterFn: (row, id, value) => {
      //   return value.includes(row.getValue(id));
      // },
    },
    {
      accessorKey: "is_read_only",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Read Only" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("is_read_only") ? "YES" : "NO"}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "is_closed",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Closed" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("is_closed") ? "YES" : "NO"}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "is_won",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Won" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("is_won") ? "YES" : "NO"}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "is_invoiced",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Invoiced" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("is_invoiced") ? "YES" : "NO"}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "is_paid",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Paid" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("is_paid") ? "YES" : "NO"}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "days_to_close",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Days to close" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("days_to_close")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "closed_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Closed" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {format(new Date(row.getValue("closed_at")), "yyyy-MM-dd")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {format(
              new Date(row.getValue("created_at")),
              "yyyy-MM-dd HH:mm:ss"
            )}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "updated_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Updated" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {format(
              new Date(row.getValue("updated_at")),
              "yyyy-MM-dd HH:mm:ss"
            )}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "customer_deal_count",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer Deal Count" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("customer_deal_count")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "contract_length_months",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Contract Length" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("contract_length_months")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "invoice_length_months",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Invoice Period" />
      ),
      cell: ({ row }) => {
        // const priority = priorities.find(
        //   (priority) => priority.value === row.getValue("priority")
        // );

        // if (!priority) {
        //   return null;
        // }

        return (
          <div className="flex items-center">
            {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span> */}
            {row.getValue("invoice_length_months")}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "sync",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Sync" />
      ),
      cell: ({ row }) => (
        <div className="flex items-center">
          {row.getValue("is_read_only") ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  key={row.id}
                  disabled={isAPILoading && clickedRow === row.id}
                  onClick={async () => {
                    await updateDeal(row.getValue("id"), {
                      is_read_only: false,
                    });
                    mutate();
                  }}
                  size="icon"
                  variant={row.getValue("is_read_only") ? "ghost" : "default"}
                >
                  <Lock className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <div className="text-md font-semibold">Sync Disabled</div>
                <p className="text-sm text-muted-foreground">
                  Click to unlock deal and enable sync.
                </p>
              </TooltipContent>
            </Tooltip>
          ) : (
            <Button
              key={row.id}
              disabled={isAPILoading && clickedRow === row.id}
              onClick={async () => {
                setClickedRow(row.id);
                await syncDealsByProvider(
                  row.getValue("provider"),
                  row.getValue("provider_id")
                );
                mutate();
                setClickedRow(null);
              }}
              size="icon"
              variant={row.getValue("is_read_only") ? "ghost" : "default"}
            >
              {isAPILoading && clickedRow === row.id ? (
                <Icons.spinner className="h-4 w-4 animate-spin" />
              ) : (
                <RefreshCcw className="h-4 w-4" />
              )}
            </Button>
          )}
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
      id: "provider_data_viewer",
      cell: ({ row }) => <ProviderDataViewer row={row} />,
    },
    // {
    //   id: "actions",
    //   cell: ({ row }) => <DataTableRowActions row={row} />,
    // },
  ];
};
