import { getCommissionForDateRange, getCommissionsValue } from "@revelate/calc";
import {
  CommissionMonth,
  CommissionUser,
  NestedCommission,
  NestedUser,
} from "@revelate/types";
import { DateRange } from "react-day-picker";
import { endOfMonth, format, parse, startOfMonth } from "date-fns";
import { enUS } from "date-fns/locale";
import { getFullName, getUserInitials, sortByStringKey } from "@revelate/utils";

export const getUsersWithCommissions = (
  users: NestedUser[],
  onlyWithCommissions = false
) => {
  return users
    ?.map((user) => {
      const value = getCommissionsValue(user?.commissions);

      return {
        ...user,
        value,
      };
    })
    ?.sort((a, b) => b.value - a.value)
    ?.filter((user) => (onlyWithCommissions ? user.value > 0 : user));
};

// export const excludeSuperAdmins = (users: NestedUser[]) => {
//   return users?.filter((user) => user.role !== "super_admin");
// };

export const getUsersList = (
  users: NestedUser[],
  projectedUsers?: NestedUser[]
) => {
  // Calculate commission per user for period
  const usersList: CommissionUser[] = [];
  users?.sort(sortByStringKey("first_name")).map((user) => {
    const { commissions } = user;
    const projectedUser = projectedUsers?.find(
      (projectedUser) => projectedUser.id === user.id
    );
    const { commissions: projectedCommissions } = projectedUser || {};
    // const commissions = getCommissionForDateRange(userCommissions, dateRange);
    const value = getCommissionsValue(commissions);
    const valueProjected = projectedCommissions
      ? getCommissionsValue(projectedCommissions)
      : 0;
    usersList.push({
      name: getFullName(user),
      email: user.email,
      avatar: user.avatar_url || "",
      avatar_fallback: getUserInitials(user),
      value,
      valueProjected,
      id: user.id,
    });
  });
  usersList.sort((a, b) => b.value - a.value);
  return usersList;
};

export const getMonthsList = (
  commissions: NestedCommission[],
  dateRange?: DateRange
) => {
  // Calculate commission per month for period
  const commissionMonths: CommissionMonth[] = [];
  const start = dateRange?.from;
  const end = dateRange?.to;
  if (!start || !end) return commissionMonths;
  const d = new Date(start);
  while (d <= new Date(end)) {
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const commissionsForMonth = getCommissionForDateRange(commissions, {
      from: startOfMonth(d),
      to: endOfMonth(d),
    });
    // if (value > 0)
    commissionMonths.push({
      month,
      year,
      value: getCommissionsValue(commissionsForMonth),
      label: format(
        parse(`${year}-${month}`, "yyyy-MM", new Date()),
        "LLL yyyy",
        { locale: enUS }
      ),
    });
    d.setMonth(d.getMonth() + 1);
  }
  return commissionMonths;
};
