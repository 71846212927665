import {
  DateRange,
  TimePeriod,
  DateRangeStrict,
  YearAndMonth,
} from "@revelate/types";
import { dateRangePresets } from "@revelate/constants";
import {
  startOfYear,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  getMonth,
  differenceInCalendarMonths,
  differenceInDays,
  subDays,
  format,
  parse,
  endOfDay,
  startOfDay,
  addDays,
  addQuarters,
  endOfQuarter,
  subQuarters,
  subMonths,
  endOfYear,
  isSameDay,
} from "date-fns";
import { enUS } from "date-fns/locale";

export const getYTD = (toDate: Date): DateRange => {
  const from = startOfYear(toDate);
  const to = toDate;
  return { from, to };
};

export const getStartOfYearToEndOfMonth = (toDate: Date): DateRange => {
  const from = startOfYear(toDate);
  const to = endOfMonth(toDate);
  return { from, to };
};

export const getStartOfYearToEndOfPreviousTimePeriod = (
  toDate: Date,
  timePeriod: TimePeriod
): DateRange => {
  const from = startOfYear(toDate);
  const to = getToDateForTimePeriod(timePeriod, toDate, true);
  return { from, to };
};

const isSameMonth = (date1: Date, date2: Date): boolean => {
  return getMonth(date1) === getMonth(date2);
};

const getFromDateForTimePeriod = (
  timePeriod: TimePeriod,
  date: Date,
  previous: boolean
): Date => {
  if (timePeriod === "quarter")
    return startOfQuarter(previous ? subQuarters(date, 1) : date);
  if (timePeriod === "year") return startOfYear(date);
  return startOfMonth(previous ? subMonths(date, 1) : date);
};

const getToDateForTimePeriod = (
  timePeriod: TimePeriod,
  date: Date,
  previous: boolean
): Date => {
  if (timePeriod === "quarter")
    return endOfQuarter(previous ? subQuarters(date, 1) : date);
  if (timePeriod === "year") return endOfYear(date);
  return endOfMonth(previous ? subMonths(date, 1) : date);
};

export const getStartEndDates = (
  dateRange: DateRange,
  date: Date,
  timePeriod: TimePeriod,
  previous = false
): DateRange => {
  const { from: dateRangeFrom, to: dateRangeTo } = dateRange || {};
  const from =
    dateRangeFrom && isSameMonth(dateRangeFrom, date)
      ? dateRangeFrom
      : getFromDateForTimePeriod(timePeriod, date, previous);
  const to =
    dateRangeTo && isSameMonth(dateRangeTo, date)
      ? dateRangeTo
      : getToDateForTimePeriod(timePeriod, date, previous);
  return { from, to };
};

export const shouldCalculate = (to: Date, timePeriod?: TimePeriod): boolean => {
  if (!timePeriod) return false;

  const month = getMonth(to) + 1;
  if (timePeriod === "quarter") {
    return month % 3 === 0;
  }
  if (timePeriod === "year") {
    return month === 12;
  }
  // Default to month
  return true;
};

export const getMonthsForTimePeriod = (timePeriod: TimePeriod): number => {
  if (timePeriod === "quarter") return 3;
  if (timePeriod === "year") return 12;
  return 1;
};

export const getPreviousDateRange = (dateRange: DateRange) => {
  if (!dateRange.from || !dateRange.to)
    return {
      from: undefined,
      to: undefined,
    } as DateRange;

  // Get a date range as long as the current one but shifted back by the same amount
  const from = subDays(
    dateRange.from,
    differenceInDays(dateRange.to, dateRange.from)
  );
  const to = subDays(
    dateRange.to,
    differenceInDays(dateRange.to, dateRange.from)
  );
  return { from, to };
};

export const getHumanReadableDateRangeName = () => {
  return "";
  // if (!dateRange.from || !dateRange.to) return "";

  // const from = new Date(dateRange.from);
  // const to = new Date(dateRange.to);
  // // Check if range is exactly one month
  // if (
  //   from.getMonth() === to.getMonth() &&
  //   from.getFullYear() === to.getFullYear()
  // ) {
  //   return `${from.toLocaleString("default", {
  //     month: "long",
  //   })} ${from.getFullYear()}`;
  // }
  // // Check if range is exactly one quarter
  // if (
  //   from.getMonth() === to.getMonth() &&
  //   from.getFullYear() === to.getFullYear()
  // ) {
  //   return `${from.toLocaleString("default", {
  //     month: "long",
  //   })} ${from.getFullYear()}`;
  // }
  // // Check if range is exactly one year
  // if (from.getFullYear() === to.getFullYear()) {
  //   return `${from.getFullYear()}`;
  // }
  // return `${from.toLocaleString("default", {
  //   month: "long",
  // })} ${from.getFullYear()} - ${to.toLocaleString("default", {
  //   month: "long",
  // })} ${to.getFullYear()}`;
};

export const syncAvailable = (dateRange: DateRange): boolean => {
  if (!dateRange?.from || !dateRange?.to) return false;
  const months = differenceInCalendarMonths(
    addDays(dateRange.to, 1),
    dateRange.from
  );
  return months <= 3 || process.env.NODE_ENV === "development";
};

export const getMonthNameFromInteger = (
  month: number,
  abbreviatedName = false
): string => {
  const date = new Date();
  date.setMonth(month);
  return format(new Date(date), abbreviatedName ? "MMM" : "MMMM", {
    locale: enUS,
  });
};

const addQuarterToFriendlyName = (
  friendlyName: string[],
  haystack: number[],
  needle: number[],
  quarterName: string
) => {
  if (needle.every((i) => haystack.includes(i))) {
    friendlyName.push(quarterName);
  } else {
    needle.map((i) =>
      haystack.includes(i)
        ? friendlyName.push(getMonthNameFromInteger(i - 1, true))
        : false
    );
  }
};

export const getFriendlyNameOfMonths = (
  months: number[] | undefined
): string => {
  if (!months) return "Always";
  if (months?.length === 12) return "Always";
  if (months?.length === 0) return "No months selected";
  const friendlyName: string[] = [];
  addQuarterToFriendlyName(friendlyName, months, [1, 2, 3], "Q1");
  addQuarterToFriendlyName(friendlyName, months, [4, 5, 6], "Q2");
  addQuarterToFriendlyName(friendlyName, months, [7, 8, 9], "Q3");
  addQuarterToFriendlyName(friendlyName, months, [10, 11, 12], "Q4");
  if (friendlyName.length === 1) return friendlyName[0];
  const last = friendlyName.pop();
  const result = friendlyName.join(", ") + " and " + last;
  return result;
};

export const getSyncDateRange = (
  fromStr?: string,
  toStr?: string,
  subDaysFallback = 1,
  addDaysFallback = 0
): DateRangeStrict => {
  // Get from and to dates
  const from = fromStr
    ? parse(fromStr, "yyyy-MM-dd", new Date())
    : startOfDay(subDays(new Date(), subDaysFallback));
  const to = toStr
    ? parse(toStr, "yyyy-MM-dd", new Date())
    : endOfDay(addDays(new Date(), addDaysFallback));
  return { from, to };
};

export const yearMonthExistsInArray = (
  item: YearAndMonth,
  array: YearAndMonth[]
): boolean => {
  for (const arrayItem of array) {
    if (arrayItem.year === item.year && arrayItem.month === item.month)
      return true;
  }
  return false;
};

export function compareYearMonth(a: YearAndMonth, b: YearAndMonth): -1 | 0 | 1 {
  if (a.year < b.year) return -1;

  if (a.year === b.year) {
    if (a.month === b.month) return 0;
    if (a.month < b.month) return -1;
  }

  return 1;
}

export const getPreviousMonthDateRange = (): DateRange => {
  const today = new Date();
  const from = startOfMonth(subMonths(today, 1));
  const to = endOfMonth(subMonths(today, 1));
  return { from, to };
};

export const getCurrentMonthDateRange = (): DateRange => {
  const today = new Date();
  const from = startOfMonth(today);
  const to = endOfMonth(today);
  return { from, to };
};

export const getNextQuarterDateRange = (): DateRangeStrict => {
  const today = new Date();
  const from = addQuarters(startOfQuarter(today), 1);
  const to = endOfQuarter(from);
  return { from, to };
};

export const getDateRangePreset = (dateRange: DateRange) => {
  // Find matching preset
  const matchingPreset = dateRangePresets.find(
    (preset) =>
      dateRange.from &&
      dateRange.to &&
      isSameDay(preset.range.from, dateRange.from) &&
      isSameDay(preset.range.to, dateRange.to)
  );

  return matchingPreset;
};
