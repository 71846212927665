import * as React from "react";
import { CheckIcon, DatabaseZap } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getFullName, sortByStringKey } from "@revelate/utils";
import { UserWithDeals } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { cn } from "@/lib/utils";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";
import {
  transferCommissions,
  transferDeals,
  transferGoals,
  transferPlans,
} from "@/lib/supabase";

export function EmployeeCombobox({
  user,
  employees,
  mutate,
}: {
  user: UserWithDeals;
  employees: UserWithDeals[];
  mutate: KeyedMutator<UserWithDeals[]>;
}) {
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserWithDeals | null>(
    null
  );

  const handleSelect = async (userId: string) => {
    const selectedUser = employees.find((e) => e.id === userId);
    if (!selectedUser) return;
    setSelectedUser(selectedUser);
    setDialogOpen(true);
  };

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button size="icon" variant="outline">
            <DatabaseZap className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Select employee to transfer data..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {employees
                  ?.filter((e) => user.id !== e.id)
                  .sort(sortByStringKey("first_name"))
                  .map((user) => (
                    <CommandItem
                      key={user.id}
                      value={user.id}
                      onSelect={(id) => handleSelect(id)}
                    >
                      <span>{getFullName(user)}</span>
                      <CheckIcon
                        className={cn("ml-auto h-4 w-4", "opacity-0")}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <ConfirmDeletionDialog
        title={`Transfer ${user.email} to ${selectedUser?.email}`}
        description={`Are you sure you want to transfer all data from ${user.email} to ${selectedUser?.email}? This action cannot be reversed.`}
        key={"transfer-user-data"}
        deleteId={user.id.toString()}
        deleteOperation={async () => {
          if (!selectedUser) return;
          await transferCommissions(user, selectedUser);
          await transferGoals(user, selectedUser);
          await transferPlans(user, selectedUser);
          await transferDeals(user, selectedUser);
          mutate();
        }}
        open={dialogOpen}
        onOpenChange={() => setDialogOpen(!dialogOpen)}
      >
        <></>
      </ConfirmDeletionDialog>
    </div>
  );
}
