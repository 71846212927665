import { SignedInUser, UserWithDeals } from "@revelate/types";
import { supabaseClient } from "./client";

export const getUser = async (userId: string) => {
  const query = supabaseClient
    .from("users")
    .select("*, company:companies!public_profiles_company_id_fkey(*)")
    .eq("id", userId);

  const { data, error } = await query
    .returns<SignedInUser[]>()
    .limit(1)
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const changeCompany = async (
  user: SignedInUser,
  newCompanyId: number
) => {
  if (!user) return null;
  const { data, error } = await supabaseClient
    .from("users")
    .update({ company_id: newCompanyId })
    .eq("id", user.id)
    .select("*, company:companies!public_profiles_company_id_fkey(*)")
    .returns<SignedInUser>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

// export const getUsers = async (companyId: number) => {
//   const { data, error } = await supabaseClient
//     .from("users")
//     .select(
//       "*, quotas(*), teams(*), plans(*), deals(*), currency:currencies!users_currency_id_fkey(*)"
//     )
//     .eq("company_id", companyId)
//     .returns<NestedUser[]>();
//   if (error) {
//     throw error;
//   }
//   return data;
// };

export const updateUserCurrency = async (
  userId: string,
  currencyId: number
) => {
  const { data, error } = await supabaseClient
    .from("users")
    .update({ currency_id: currencyId })
    .eq("id", userId)
    .select(
      "*, currency:currencies!users_currency_id_fkey(*), deals!user_deals(id), plans!user_plans(id)"
    )
    .returns<UserWithDeals[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};
